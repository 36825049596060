import TestIcons from "./TestIcons";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Modal from "./Modal";

function FailedTest({ sidebarToggle, projectName }) {
  const location = useLocation();
  const reportData = location?.state?.report;
  const [data, setData] = useState(null);

  useEffect(() => {
    if (reportData?.report) {
      setData(reportData?.report);
    }
  }, [reportData?.report]);

  // const { data } = useFetchTestReport();
  return (
    <div
      className={`transition-all duration-300 ${
        sidebarToggle ? "ml-0" : "ml-64"
      }`}
    >
      <div className="grid grid-cols-[5%,94%] justify-between">
        <div style={{ backgroundColor: "#e2e3f3" }}>
          <TestIcons
            data={data}
            projectName={reportData?.projectName ?? projectName}
          />
        </div>

        <table className="p-5 m-5 border-x-[0.5px] border-[#E6E6E6] border-t-[1px] text-[#414141] rounded-t-xl overflow-hidden">
          <thead className="bg-[#EEEEEE] text-[#414141]">
            <tr>
              <th colSpan={3} className="p-2 m-2">
                <div className="flex items-center justify-center gap-3">
                  <h3 className="font-semibold text-xl">
                    {data?.body?.project_name} - {data?.body?.class_name}
                  </h3>
                </div>
              </th>
            </tr>
          </thead>

          <tr className="p-2 m-2  bg-[#0A1070] text-white">
            <th className="p-2 m-2 ">
              <h3 className="rounded-lg inline-block px-2 py-1">TIMESTAMP</h3>
            </th>
            <th className="p-2 m-2 ">
              <h3 className="ml-2 rounded-lg inline-block px-2 py-1">
                SCREENSHOT
              </h3>
            </th>
            <th className="p-2 m-2">
              <h3 className="ml-2 rounded-lg inline-block px-2 py-1">
                DETAILS
              </h3>
            </th>
          </tr>

          {data?.body?.failed_results?.map((failCase) => (
            <tr
              key={failCase?.method_name}
              className="p-2 m-2 border-b-[0.5px]  border-[#E6E6E6] hover:bg-[#F9FAFF]"
              style={{
                borderLeft: "1px solid #E6E6E6",
                borderRight: "1px solid #E6E6E6",
              }}
            >
              <td className="p-2 m-2 w-auto [0.5px] border-[#E6E6E6]">
                <h1 className="px-4 py-0 pt-4">
                  Method Name: {failCase?.method_name}
                </h1>

                <h1 className="px-4 py-0">Timestamp: {failCase?.timestamp}</h1>
                {/* <div style={{ padding: "0px 18px" }}>
                  <ExecutionTimeGraph
                    data={{
                      method_name: failCase?.method_name,
                      execution_time: failCase?.execution_time,
                      status: failCase?.status,
                    }}
                  />
                </div> */}
                <p className="p-2 m-2 mt-0 text-[#D10505]">
                  Executed Time: {failCase?.execution_time}
                </p>
              </td>
              <td className="p-2 m-2">
                <div>
                  <img
                    src={`data:image/png;base64,${failCase?.screenshot}`}
                    width={700}
                    style={{ height: "350px" }}
                    className="ml-2 px-2 py-4"
                    alt={failCase?.method_name}
                  ></img>
                </div>
              </td>

              <td className="p-2 m-2">
                <div className="py-4">
                  <div key={failCase?.method_name}>
                    <Modal
                      modalContent={failCase?.exception}
                      title={"Exception"}
                      key={failCase?.method_name}
                    />
                    {/* <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      fullWidth
                    >
                      <div className="flex flex-row justify-between">
                        <DialogTitle
                          className="!pb-0 !pt-3"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            textAlign: "center",
                          }}
                        >
                          Exception
                        </DialogTitle>
                        <button
                          className="bg-red-600 rounded-full text-white mt-2 mr-8"
                          onClick={handleCloseDialog}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-10"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                      <DialogContent>
                        <div
                          style={{
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            padding: "10px",
                            borderRadius: "4px",
                            overflowY: "auto",
                            maxHeight: "70vh", // Add a max height to control the content height
                          }}
                        >
                          {failCase.exception}
                        </div>
                      </DialogContent>
                    </Dialog> */}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}
export default FailedTest;
