import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./sprint.css";
import ProjOptions from "../../components/ProjOptions";

import LastButtons from "../../components/LastButtons";

const sortSprintData = (data) => {
  return data.sort((a, b) => {
    return a?.sprintName.localeCompare(b?.sprintName, undefined, {
      numeric: true,
    });
  });
};
function Sprints({ sidebarToggle }) {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSprint, setSelectedSprint] = useState(null);
  const [startSprint, setStartSprint] = useState();
  const [endSprint, setEndSprint] = useState();
  const [sprintsData, setSprintsData] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const [numSprints, setNumSprints] = useState(1);
  const [sprintsList, setSprintsList] = useState([]);
  const [selectedSprintData, setSelectedSprintData] = useState([]);
  // Initialize with 1 as default

  useEffect(() => {
    let currentProject = localStorage.getItem("currentProject");
    let currentSprint = localStorage.getItem("currentSprint");
    if (currentProject && currentSprint) {
      currentProject = JSON.parse(currentProject);
      currentSprint = JSON.parse(currentSprint);
      setSelectedProject(currentProject);
      setSelectedSprint(currentSprint);
    }
  }, []);

  useEffect(() => {
    // To create new Structure

    if (selectedProject?.baseInfo?.projectName) {
      const sprints = selectedProject?.sprints;
      if (sprints.length > 0) {
        const sortedSprintData = sortSprintData(sprints);
        const sprintKeys = sortedSprintData?.map(
          (sprint) => sprint?.sprintName
        );
        setSprintsData(sortedSprintData);
        setSprintsList(sprintKeys);
        setStartSprint(sprintKeys[0]);
        setEndSprint(sprintKeys[0]);
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    if (startSprint && endSprint) {
      const startSprintIndex = sprintsList.findIndex(
        (item) => item === startSprint
      );
      const endSprintIndex = sprintsList.findIndex(
        (item) => item === endSprint
      );

      if (
        startSprintIndex !== -1 &&
        endSprintIndex !== -1 &&
        startSprintIndex <= endSprintIndex
      ) {
        const activeSprints = [];

        for (let i = startSprintIndex; i <= endSprintIndex; i++) {
          activeSprints.push(sprintsData[i]);
        }

        setSelectedSprintData(activeSprints);
      } else {
        setSelectedSprintData([]);
      }
    }
  }, [startSprint, endSprint, sprintsList]);
  const handleSave = () => {
    const startNumber = parseInt(startSprint.split(" ")[1]);
    const endNumber = parseInt(endSprint.split(" ")[1]);

    if (startNumber > endNumber) {
      alert(
        "The End Sprint date should always be greater than or equal to the Start Sprint date."
      );
      return;
    }

    // localStorage.setItem("AllSprintKpi", loadedSprintsData)
    sprintsData.forEach((sprintData) => {
      localStorage.setItem(
        `sprintData_${sprintData.id}`,
        JSON.stringify(sprintData)
      );
    });

    if (startSprint !== endSprint) {
      // const selectedSprints = sprintsData.filter((sprintData) => {
      //   const sprintNumber = sprintData.id
      //     ? parseInt(sprintData.id.split(" ")[1])
      //     : 0;
      //   return sprintNumber >= startNumber && sprintNumber <= endNumber;
      // });

      navigate("/multipleSprintsChart", {
        state: {
          selectedSprints: selectedSprintData,
          startSprint,
          endSprint,
        },
      });
    } else {
      const singleSprintData = selectedSprintData[0];

      const completionRate =
        singleSprintData.plannedTasks === 0 ||
        singleSprintData.tasksCompleted === 0
          ? 0 // or 100, depending on what you want the completion rate to be when there are no planned tasks
          : Math.floor(
              (Number(singleSprintData.tasksCompleted) /
                Number(singleSprintData.plannedTasks)) *
                100 *
                1000
            ) / 1000;
      const workEfficiencyRatio =
        singleSprintData.plannedWorkHours === 0 ||
        singleSprintData.workHoursUsed === 0
          ? 0
          : Math.floor(
              (Number(singleSprintData.workHoursUsed) /
                Number(singleSprintData.plannedWorkHours)) *
                100 *
                1000
            ) / 1000;
      const inSprintDefectsRatio =
        singleSprintData.tasksCompleted === 0 ||
        singleSprintData.inSprintDefects === 0
          ? 0
          : Math.floor(
              (Number(singleSprintData.inSprintDefects) /
                Number(singleSprintData.tasksCompleted)) *
                100 *
                1000
            ) / 1000;
      const postSprintDefectsRatio =
        singleSprintData.tasksCompleted === 0 ||
        singleSprintData.postSprintDefects === 0
          ? 0
          : Math.floor(
              (Number(singleSprintData.postSprintDefects) /
                Number(singleSprintData.tasksCompleted)) *
                100 *
                1000
            ) / 1000;
      const extraTasksRate =
        singleSprintData.plannedTasks === 0 ||
        singleSprintData.extraTasksAdded === 0
          ? 0
          : Math.floor(
              (Number(singleSprintData.extraTasksAdded) /
                Number(singleSprintData.plannedTasks)) *
                100 *
                1000
            ) / 1000;

      navigate("/chart", {
        state: {
          completionRate,
          plannedTasks: singleSprintData.plannedTasks,
          tasksCompleted: singleSprintData.tasksCompleted,
          workEfficiencyRatio,
          plannedWorkHours: singleSprintData.plannedWorkHours,
          workHoursUsed: singleSprintData.workHoursUsed,
          inSprintDefectsRatio,
          postSprintDefectsRatio,
          extraTasksRate,
          extraTasksAdded: singleSprintData.extraTasksAdded,
          selectedSprint: startSprint,
        },
      });
    }
  };

  const handleInputChange = (sprintIndex, field, value) => {
    const updatedSprintsData = [...selectedSprintData];

    updatedSprintsData[sprintIndex][field] = value;
    let project = localStorage.getItem("currentProject");
    let sprint = localStorage.getItem("currentSprint");
    if (project && sprint) {
      project = JSON.parse(project);
      sprint = JSON.parse(sprint);
      if (updatedSprintsData[sprintIndex]?.sprintName === sprint?.sprintName) {
        sprint[field] = value;
        localStorage.setItem("currentSprint", JSON.stringify(sprint));
      }
      project["sprints"] = updatedSprintsData;
      localStorage.setItem("currentProject", JSON.stringify(project));
    }
    setSelectedSprintData(updatedSprintsData);
    const stateSprintData = [...sprintsData];
    stateSprintData[sprintIndex][field] = value;
    setSprintsData(stateSprintData);
  };

  const renderSprintsData = () => {
    return selectedSprintData.map((sprint, index) => {
      return (
        <tr
          key={sprint?.sprintName}
          className="points border-b-2 border-gray-400 hover:bg-gray-400"
        >
          <td className="m-4 ">{sprint?.sprintName}</td>
          <td
            className="m-4"
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "plannedTasks",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.plannedTasks}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "tasksCompleted",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.tasksCompleted}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "extraTasksAdded",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.extraTasksAdded}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "descopedTasks",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.descopedTasks}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "totalAvailableWorkHours",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.totalAvailableWorkHours}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "plannedWorkHours",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.plannedWorkHours}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "workHoursUsed",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.workHoursUsed}
          </td>
          <td
            className="m-4 "
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "inSprintDefects",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.inSprintDefects}
          </td>
          <td
            className="m-4 p-6"
            contentEditable={editMode}
            onBlur={(e) =>
              handleInputChange(
                index,
                "postSprintDefects",
                parseInt(e.target.textContent) || 0
              )
            }
          >
            {sprint?.postSprintDefects}
          </td>
        </tr>
      );
    });
  };
  return (
    <div
      className={` transition-all duration-300 ${
        sidebarToggle ? "ml-0" : "ml-64"
      }`}
    >
      <ProjOptions />
      <div className="flex justify-center gap-10 mt-6">
        <div className="dropdown-container1">
          <div className="dropdown text-xl p-2">
            <label htmlFor="start-sprint">Start Sprint:</label>
            <select
              id="start-sprint"
              value={startSprint}
              onChange={(e) => setStartSprint(e.target.value)}
            >
              {selectedProject &&
                selectedProject.sprints.map((sprint) => (
                  <option
                    key={sprint.sprintName}
                    value={sprint.sprintName}
                    className="text-xl text-center"
                  >
                    {sprint.sprintName}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="dropdown-container2">
          <div className="dropdown text-xl p-2">
            <label htmlFor="end-sprint">End Sprint:</label>
            <select
              id="end-sprint"
              value={endSprint}
              onChange={(e) => setEndSprint(e.target.value)}
            >
              {selectedProject &&
                selectedProject.sprints
                  .filter(
                    (sprint) =>
                      sprintsList.findIndex(
                        (sprintName) => sprintName === sprint.sprintName
                      ) >=
                      sprintsList.findIndex(
                        (sprintName) => sprintName === startSprint
                      )
                  )
                  .map((sprint) => (
                    <option
                      key={sprint.sprintName}
                      value={sprint.sprintName}
                      className="text-xl text-center"
                    >
                      {sprint.sprintName}
                    </option>
                  ))}
            </select>
          </div>
        </div>
      </div>

      <h1 className="flex justify-center mt-8">Selected Sprints</h1>
      <div className="flex justify-center px-6">
        <table className={`table2 ${editMode ? "editable" : ""}`}>
          <tbody className="table2">
            <tr className="hours">
              <th>Sprint No.</th>
              <th>Planned Tasks</th>
              <th>Tasks Completed</th>
              <th>Extra tasks added</th>
              <th>Descoped Tasks</th>
              <th>Total available work hours</th>
              <th>Planned work hours</th>
              <th>Work hours used</th>
              <th>In-Sprint Defects Identified</th>
              <th>Post-Sprint Defects Identified</th>
            </tr>

            {renderSprintsData()}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end gap-10 mx-6 mt-6">
        <div>
          {/* <button
            className="edit-button"
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "Edit" : "Edit"}
          </button> */}
        </div>
        <div>
          {/* {editMode && (
            <button className="save-button" onClick={handleSave}>
              Submit
            </button>
          )} */}
          {/* <button className="save-button" onClick={handleSave}>
            Submit
          </button> */}
        </div>
      </div>
      <LastButtons current={"Sprints"} handleSave={handleSave} />
    </div>
  );
}

export default Sprints;
